import API from "../../api";

export default {

    getCustomer(successCallback, errorCallback) {
        API.GET({
            url: `/customers/me`,
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    getCart(successCallback, errorCallback) {
        API.GET({
            url: `/cart/item`,
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    removeCartItem(request, successCallback, errorCallback) {
        API.DELETE({
            url: `/cart/item`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    getCheckout(successCallback, errorCallback) {
        API.POST({
            url: `/checkout`,
            params: {}
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    updateCartPhone(request, successCallback, errorCallback) {
        API.PATCH({
            url: `/cart/phone`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    updateCartEmail(request, successCallback, errorCallback) {
        API.PATCH({
            url: `/cart/email`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    updateCart(request, successCallback, errorCallback) {
        API.PUT({
            url: `/cart`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    addCartItem(request, successCallback, errorCallback) {
        API.POST({
            url: `/cart/item`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    emptyCart(successCallback, errorCallback) {
        API.DELETE({
            url: `/cart`
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },
}