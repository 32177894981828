import API from "api";

export default {

    viewedProduct(request, successCallback, errorCallback) {
        API.POST({
            url: `/cart/tracking/viewed/product`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    viewedVariant(request, successCallback, errorCallback) {
        API.POST({
            url: `/cart/tracking/viewed/variant`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    getProduct(id, successCallback, errorCallback) {
        API.GET({
            url: `/index/products/${id}`,
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    getCategory(handle, successCallback, errorCallback) {
        API.GET({
            url: `/categories/handle/${handle}`,
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    getButtonPermutations(productId, request, selectedName, selectedValue, fixedName, fixedValue, successCallback, errorCallback) {
        API.POST({
            url: `/index/products/permutations?productId=${productId}&newSelectedOptionName=${selectedName}&newSelectedOptionValue=${selectedValue}&fixedOptionName=${fixedName}&fixedOptionValue=${fixedValue}`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    getBatchesByProductVariantId(productVariantId, successCallback, errorCallback) {
        API.GET({
            url: `/batch/product-variant/${productVariantId}`,
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    getBatchesByProductId(productId, successCallback, errorCallback) {
        API.GET({
            url: `/batch/product/${productId}`,
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    createRecentHistory(request, successCallback, errorCallback) {
        API.POST({
            url: `/product/recent-history/${request.productId}`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },
}