import API from "api";

export default {

    viewedCategory(categoryName, successCallback, errorCallback) {
        API.GET({
            url: `/cart/tracking/viewed/category/${categoryName}`,
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    filterProductsByCategoryHandle(handle, request, searchText, successCallback, errorCallback) {
        if (searchText === "null" || searchText == null) {
            searchText = "";
        }
        API.POST({
            url: `/index/products/filter/${handle}?searchText=${searchText}`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) => {
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },
}
